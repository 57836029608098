p {
  font-size: 1em;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  color: white;
  padding: 0px;
}

.App-link {
  color: #61dafb;
}

.area-content {
  /* margin-top: 2em; */
}

.background-logo{
  position: absolute;
  left:0;
  top:0;
  width: 100%;
  height: auto;
  opacity:0.15;
}


